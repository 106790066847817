import AbstractFilter, { InitialFilterState } from '../../../common/search/store/AbstractFilter';

const validTypes = ['week', 'weekend', 'midWeek', 'longWeekend'];

export default class FlexibleDurationFilter extends AbstractFilter {
    static NAME = 'flexibleDurationType';

    #type = 'weekend';

    #months: number[] = [];

    #enabled = false;

    clear(): void {
        this.#type = 'weekend';
        this.notify();
    }

    set type(type: string) {
        if (type === this.#type) return;

        this.#type = type;
        this.notify();
    }

    set months(months: number[]) {
        months.filter((month) => month > 0 && month < 13);

        if (months === this.#months) return;

        this.#months = months;
        this.notify();
    }

    get parameters(): Record<string, string | null> {
        if (!this.#enabled) return { flexibleDurationType: null, flexibleBookingMonths: null };

        const type = this.#type ? this.#type.toString() : null;
        const months = this.#months.length ? this.#months.join(',') : null;

        return { flexibleDurationType: type, flexibleBookingMonths: months };
    }

    set enabled(enabled: boolean) {
        const old = this.#enabled;
        this.#enabled = enabled;

        if (old !== enabled) this.notify();
    }

    get enabled(): boolean {
        return this.#enabled;
    }

    public setFlexibleStay(type: string, months: number[]): void {
        this.type = validTypes.includes(type) ? type : 'weekend';
        this.months = months;
    }

    public setInitialState(state: InitialFilterState): void {
        const flexibleDurationType = state.get('flexibleDurationType');
        const flexibleBookingMonths = state.get('flexibleBookingMonths') || '';

        if (flexibleDurationType === undefined) {
            this.#type = 'weekend';
            return;
        }

        this.#enabled = true;
        this.#type = validTypes.includes(flexibleDurationType) ? flexibleDurationType : 'weekend';
        this.#months = flexibleBookingMonths
            .split(',')
            .filter((month) => month.length)
            .map((month) => Number(month));
    }
}
