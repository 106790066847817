import AbstractFilter, { InitialFilterState } from '../../../common/search/store/AbstractFilter';

export default class AggregationFilter extends AbstractFilter {
    readonly #parameterName: string;

    #selection: string[] = [];

    get parameterName(): string {
        return this.#parameterName;
    }

    get selection(): string[] {
        return [...this.#selection];
    }

    get parameters(): Record<string, string | null> {
        if (this.#selection.length === 0) {
            return {};
        }

        return {
            [this.#parameterName]: this.#selection.join(',')
        };
    }

    constructor(parameterName: string) {
        super();

        this.#parameterName = parameterName;
    }

    public setInitialState(state: InitialFilterState): void {
        const selection = state.get(this.#parameterName);
        this.#selection = selection ? selection.split(',') : [];
    }

    public add(value: string): void {
        if (this.#selection.includes(value)) return;

        this.#selection.push(value);
        this.notify();
    }

    public remove(value: string): void {
        const index = this.#selection.indexOf(value);

        if (index === -1) return;

        this.#selection.splice(index, 1);
        this.notify();
    }

    public clear(): void {
        this.#selection = [];
        this.notify();
    }
}
