import AbstractFilter, { InitialFilterState } from './AbstractFilter';

export default class DestinationFilter extends AbstractFilter {
    static NAME = 'destination';

    public readonly TYPE_COUNTRY = 'country';

    public readonly TYPE_REGION = 'region';

    public readonly TYPE_DEPARTMENT = 'department';

    public readonly TYPE_PLACE = 'place';

    #country: string | null = null;

    #region: string | null = null;

    #department: string | null = null;

    #place: string | null = null;

    setDestination(type: string, value: string): void {
        switch (type) {
            case this.TYPE_COUNTRY:
                this.country = value;
                break;
            case this.TYPE_REGION:
                this.region = value;
                break;
            case this.TYPE_DEPARTMENT:
                this.department = value;
                break;
            case this.TYPE_PLACE:
                this.place = value;
                break;
            default:
                throw Error('Invalid type is given.');
        }
    }

    clear(): void {
        this.country = null;
        this.region = null;
        this.department = null;
        this.place = null;
    }

    get empty(): boolean {
        return (
            this.#country === null &&
            this.#place === null &&
            this.#department === null &&
            this.#region === null
        );
    }

    get parameters(): Record<string, string | null> {
        return {
            [this.TYPE_COUNTRY]: this.#country,
            [this.TYPE_REGION]: this.#region,
            [this.TYPE_DEPARTMENT]: this.#department,
            [this.TYPE_PLACE]: this.#place
        };
    }

    set country(value: string | null) {
        if (this.#country === value) return;

        this.#country = value || null;
        this.notify();
    }

    set region(value: string | null) {
        if (this.#region === value) return;

        this.#region = value || null;
        this.notify();
    }

    set department(value: string | null) {
        if (this.#department === value) return;

        this.#department = value || null;
        this.notify();
    }

    set place(value: string | null) {
        if (this.#place === value) return;

        this.#place = value || null;
        this.notify();
    }

    setInitialState(state: InitialFilterState): void {
        const types = [this.TYPE_COUNTRY, this.TYPE_REGION, this.TYPE_DEPARTMENT, this.TYPE_PLACE];
        types.forEach((type) => {
            const value = state.get(type);
            if (value !== undefined && value.length > 0) {
                this.setDestination(type, value);
            }
        });
    }
}
