import AbstractFilter, { InitialFilterState } from '../../../common/search/store/AbstractFilter';

export default class BedroomsFilter extends AbstractFilter {
    static NAME = 'bedrooms';

    #bedrooms = 0;

    clear(): void {
        this.#bedrooms = 0;
        this.notify();
    }

    set bedrooms(value: number) {
        this.#bedrooms = value;
        this.notify();
    }

    get parameters(): Record<string, string | null> {
        if (this.#bedrooms < 1) {
            return { bedrooms: null };
        }

        return { bedrooms: this.#bedrooms.toString() };
    }

    setInitialState(state: InitialFilterState): void {
        const bedrooms = state.get('bedrooms');
        this.#bedrooms = bedrooms && bedrooms !== 'all' ? Number(bedrooms) : 0;
    }
}
