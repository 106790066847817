var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["@naturehouse/design-system/components/atoms/anchor/Anchor.html.njk"] = require( "@naturehouse/design-system/components/atoms/anchor/Anchor.html.njk" );
dependencies["@naturehouse/design-system/components/protons/icon/Icon.macro.njk"] = require( "@naturehouse/design-system/components/protons/icon/Icon.macro.njk" );




var shim = require("/builds/natuurhuisje/nature.house/symfony/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["templates/components/molecules/destinations-list.html.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
["pwId", "title", "list"], 
["icon", "description"], 
function (l_pwId, l_title, l_list, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("pwId", l_pwId);
frame.set("title", l_title);
frame.set("list", l_list);
frame.set("icon", Object.prototype.hasOwnProperty.call(kwargs, "icon") ? kwargs["icon"] : runtime.contextOrFrameLookup(context, frame, "undefined"));frame.set("description", Object.prototype.hasOwnProperty.call(kwargs, "description") ? kwargs["description"] : runtime.contextOrFrameLookup(context, frame, "undefined"));var t_2 = "";t_2 += "\n    <nav class=\"nh-destination-search__section\">\n        <span class=\"nh-destination-search__title\">";
t_2 += runtime.suppressValue(l_title, env.opts.autoescape);
t_2 += "</span>\n        ";
if(runtime.contextOrFrameLookup(context, frame, "description")) {
t_2 += "\n            <p class=\"nh-destination-search__description\">\n                ";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "description"), env.opts.autoescape);
t_2 += "\n            </p>\n        ";
;
}
t_2 += "\n        <ul class=\"nh-destination-search__list\" data-pw=\"destination-list-";
t_2 += runtime.suppressValue(l_pwId, env.opts.autoescape);
t_2 += "\">\n            ";
frame = frame.push();
var t_5 = l_list;
if(t_5) {t_5 = runtime.fromIterator(t_5);
var t_4 = t_5.length;
for(var t_3=0; t_3 < t_5.length; t_3++) {
var t_6 = t_5[t_3];
frame.set("item", t_6);
frame.set("loop.index", t_3 + 1);
frame.set("loop.index0", t_3);
frame.set("loop.revindex", t_4 - t_3);
frame.set("loop.revindex0", t_4 - t_3 - 1);
frame.set("loop.first", t_3 === 0);
frame.set("loop.last", t_3 === t_4 - 1);
frame.set("loop.length", t_4);
t_2 += "\n                ";
var t_7;
t_7 = runtime.memberLookup((t_6),"content");
frame.set("label", t_7, true);
if(frame.topLevel) {
context.setVariable("label", t_7);
}
if(frame.topLevel) {
context.addExport("label", t_7);
}
t_2 += "\n                ";
var t_8;
t_8 = runtime.memberLookup((t_6),"url");
frame.set("value", t_8, true);
if(frame.topLevel) {
context.setVariable("value", t_8);
}
if(frame.topLevel) {
context.addExport("value", t_8);
}
t_2 += "\n                ";
var t_9;
t_9 = {"type": "radio","id": "nh-destination-search__input-" + "" + runtime.contextOrFrameLookup(context, frame, "value"),"name": "destination","value": runtime.contextOrFrameLookup(context, frame, "value"),"data": {"data-type": runtime.memberLookup((t_6),"type"),"data-term": runtime.memberLookup((t_6),"term")}};
frame.set("input", t_9, true);
if(frame.topLevel) {
context.setVariable("input", t_9);
}
if(frame.topLevel) {
context.addExport("input", t_9);
}
t_2 += "\n\n                <li\n                    class=\"nh-destination-search__list-item\"\n                    data-type=\"";
t_2 += runtime.suppressValue(env.getFilter("default").call(context, runtime.memberLookup((t_6),"type"),l_pwId), env.opts.autoescape);
t_2 += "\"\n                    data-term=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((t_6),"term"), env.opts.autoescape);
t_2 += "\"\n                    data-pw=\"";
t_2 += runtime.suppressValue(env.getFilter("lower").call(context, runtime.memberLookup((t_6),"term")), env.opts.autoescape);
t_2 += "\"\n                    data-value=\"";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "value"), env.opts.autoescape);
t_2 += "\"\n                >\n                    ";
if(runtime.memberLookup((t_6),"type") == "house") {
t_2 += "\n                        ";
var t_10;
t_10 = runtime.memberLookup((t_6),"url");
frame.set("href", t_10, true);
if(frame.topLevel) {
context.setVariable("href", t_10);
}
if(frame.topLevel) {
context.addExport("href", t_10);
}
t_2 += "\n                        ";
var t_11;
t_11 = "_blank";
frame.set("target", t_11, true);
if(frame.topLevel) {
context.setVariable("target", t_11);
}
if(frame.topLevel) {
context.addExport("target", t_11);
}
t_2 += "\n                        ";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("@naturehouse/design-system/components/atoms/anchor/Anchor.html.njk", false, "templates/components/molecules/destinations-list.html.njk", false, function(t_13,t_12) {
if(t_13) { cb(t_13); return; }
callback(null,t_12);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_15,t_14) {
if(t_15) { cb(t_15); return; }
callback(null,t_14);});
});
tasks.push(
function(result, callback){
t_2 += result;
callback(null);
});
env.waterfall(tasks, function(){
t_2 += "\n                    ";
});
}
else {
t_2 += "\n                        ";
if(runtime.contextOrFrameLookup(context, frame, "icon")) {
t_2 += "\n                            ";
env.getTemplate("@naturehouse/design-system/components/protons/icon/Icon.macro.njk", false, "templates/components/molecules/destinations-list.html.njk", false, function(t_17,t_16) {
if(t_17) { cb(t_17); return; }
t_16.getExported(function(t_18,t_16) {
if(t_18) { cb(t_18); return; }
frame.set("iconTemplate", t_16);
t_2 += "\n                            ";
t_2 += runtime.suppressValue(env.getFilter("safe").call(context, (lineno = 37, colno = 50, runtime.callWrap(runtime.memberLookup((t_16),"render"), "iconTemplate[\"render\"]", context, [{"name": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "icon")),"name"),"size": env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "icon")),"size"),"1"),"custom": env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "icon")),"custom"),false)}]))), env.opts.autoescape);
t_2 += "\n                        ";
})});
}
t_2 += "\n                        <span>\n                            ";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "label"), env.opts.autoescape);
t_2 += "\n                        </span>\n                    ";
;
}
t_2 += "\n                </li>\n            ";
;
}
}
frame = frame.pop();
t_2 += "\n        </ul>\n    </nav>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_2);
});
context.addExport("render_list");
context.setVariable("render_list", macro_t_1);
output += "\n\n";
if(env.getFilter("length").call(context, env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "recent"),[]))) {
output += "\n    ";
output += runtime.suppressValue((lineno = 54, colno = 18, runtime.callWrap(macro_t_1, "render_list", context, ["recent",runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"recent"),runtime.contextOrFrameLookup(context, frame, "recent"),{"name": "history"}])), env.opts.autoescape);
output += "\n";
;
}
output += "\n";
if(env.getFilter("length").call(context, env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "country"),[]))) {
output += "\n    ";
output += runtime.suppressValue((lineno = 64, colno = 18, runtime.callWrap(macro_t_1, "render_list", context, ["country",runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"country"),runtime.contextOrFrameLookup(context, frame, "country")])), env.opts.autoescape);
output += "\n";
;
}
output += "\n";
if(env.getFilter("length").call(context, env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "region"),[]))) {
output += "\n    ";
output += runtime.suppressValue((lineno = 71, colno = 18, runtime.callWrap(macro_t_1, "render_list", context, ["region",runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"region"),runtime.contextOrFrameLookup(context, frame, "region")])), env.opts.autoescape);
output += "\n";
;
}
output += "\n";
if(env.getFilter("length").call(context, env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "department"),[]))) {
output += "\n    ";
output += runtime.suppressValue((lineno = 78, colno = 18, runtime.callWrap(macro_t_1, "render_list", context, ["department",runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"department"),runtime.contextOrFrameLookup(context, frame, "department")])), env.opts.autoescape);
output += "\n";
;
}
output += "\n";
if(env.getFilter("length").call(context, env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "natuurgebieden"),[]))) {
output += "\n    ";
output += runtime.suppressValue((lineno = 85, colno = 18, runtime.callWrap(macro_t_1, "render_list", context, ["natural-areas",runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"natural_areas"),runtime.contextOrFrameLookup(context, frame, "natuurgebieden")])), env.opts.autoescape);
output += "\n";
;
}
output += "\n";
if(env.getFilter("length").call(context, env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "plaatsen"),[]))) {
output += "\n    ";
output += runtime.suppressValue((lineno = 92, colno = 18, runtime.callWrap(macro_t_1, "render_list", context, ["places",runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"places"),runtime.contextOrFrameLookup(context, frame, "plaatsen")])), env.opts.autoescape);
output += "\n";
;
}
output += "\n";
if(env.getFilter("length").call(context, env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "house"),[]))) {
output += "\n    ";
output += runtime.suppressValue((lineno = 99, colno = 18, runtime.callWrap(macro_t_1, "render_list", context, ["house",runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"house"),runtime.contextOrFrameLookup(context, frame, "house"),{"name": "external-link","size": 1}])), env.opts.autoescape);
output += "\n";
;
}
output += "\n\n";
if(runtime.contextOrFrameLookup(context, frame, "no_results")) {
output += "\n    <div class=\"nh-destination-search__no_results\">\n        <p>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"no_results"), env.opts.autoescape);
output += "</p>\n    </div>\n";
;
}
output += "\n\n";
if(env.getFilter("length").call(context, (runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "keyword")),0)),"content"))) > 0) {
output += "\n    ";
output += runtime.suppressValue((lineno = 117, colno = 18, runtime.callWrap(macro_t_1, "render_list", context, ["keywords",runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"keyword"),runtime.contextOrFrameLookup(context, frame, "keyword"),runtime.contextOrFrameLookup(context, frame, "undefined"),runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"keywords_description")])), env.opts.autoescape);
output += "\n";
;
}
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["templates/components/molecules/destinations-list.html.njk"] , dependencies)