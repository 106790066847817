import AbstractFilter, { InitialFilterState } from '../../../common/search/store/AbstractFilter';

export default class GeoRadiusFilter extends AbstractFilter {
    public static readonly NAME: string = 'radius';

    public static readonly DEFAULT: number = 30;

    public readonly min: number = 5;

    public readonly max: number = 80;

    #radius: number = GeoRadiusFilter.DEFAULT;

    set radius(value: number) {
        this.#radius = value;
        this.notify();
    }

    get parameters(): Record<string, string | null> {
        if (this.#radius < this.min) {
            return { radius: this.min.toString() };
        }

        if (this.#radius > this.max) {
            return { radius: this.max.toString() };
        }

        return { radius: this.#radius.toString() };
    }

    public clear(): void {
        this.#radius = GeoRadiusFilter.DEFAULT;
        this.notify();
    }

    public setInitialState(state: InitialFilterState): void {
        const radius = state.get(GeoRadiusFilter.NAME);
        this.#radius = radius ? Number(radius) : GeoRadiusFilter.DEFAULT;
    }
}
