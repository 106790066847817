import { Observer, Subject } from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';
import AbstractFilter, { InitialFilterState } from '../../../common/search/store/AbstractFilter';
import DestinationFilter from '../../../common/search/store/DestinationFilter';
import PaginationFilter from '../../../common/search/store/PaginationFilter';
import AggregationFilter from './AggregationFilter';
import ArrivalDepartureFilter from './ArrivalDepartureFilter';
import BedroomsFilter from './BedroomsFilter';
import FlexibleArrivalDateFilter from './FlexibleArrivalDateFilter';
import GeoFilter from './GeoFilter';
import KeywordFilter from './KeywordFilter';
import PopularPageFilter from './PopularPageFilter';
import PriceFilter from './PriceFilter';
import TravelPartyFilter from './TravelPartyFilter';
import FlexibleDurationFilter from './FlexibleDurationFilter';
import GeoRadiusFilter from './GeoRadiusFilter';

class HouseSearchFilters implements Observer {
    readonly #filters: Map<string, AbstractFilter>;

    static #instance: HouseSearchFilters | null = null;

    static readonly #persistentFilters = [
        'destination',
        'keyword',
        'arrivalDeparture',
        'travelParty',
        'popularPage'
    ];

    static aggregateFilters = [
        'themes',
        'facilities',
        'types',
        'locationDescriptions',
        'sustainability',
        'houseRating',
        'natureRating'
    ];

    public static getInstance(
        initialState: InitialFilterState | undefined = undefined
    ): HouseSearchFilters {
        if (HouseSearchFilters.#instance === null) {
            HouseSearchFilters.#instance = new HouseSearchFilters(
                new Map(
                    Object.entries({
                        [DestinationFilter.NAME]: new DestinationFilter(),
                        [GeoFilter.NAME]: new GeoFilter(),
                        [KeywordFilter.NAME]: new KeywordFilter(),
                        [PopularPageFilter.NAME]: new PopularPageFilter(),
                        [ArrivalDepartureFilter.NAME]: new ArrivalDepartureFilter(),
                        [FlexibleArrivalDateFilter.NAME]: new FlexibleArrivalDateFilter(),
                        [FlexibleDurationFilter.NAME]: new FlexibleDurationFilter(),
                        [TravelPartyFilter.NAME]: new TravelPartyFilter(),
                        [BedroomsFilter.NAME]: new BedroomsFilter(),
                        [PriceFilter.NAME]: new PriceFilter(),
                        [PaginationFilter.NAME]: PaginationFilter.getInstance(),
                        [GeoRadiusFilter.NAME]: new GeoRadiusFilter(),
                        themes: new AggregationFilter('themes'),
                        facilities: new AggregationFilter('facilities'),
                        types: new AggregationFilter('types'),
                        locationDescriptions: new AggregationFilter('locationDescriptions'),
                        sustainability: new AggregationFilter('sustainability'),
                        houseRating: new AggregationFilter('houseRating'),
                        natureRating: new AggregationFilter('natureRating')
                    })
                )
            );
        }

        if (!initialState) {
            return HouseSearchFilters.#instance;
        }

        HouseSearchFilters.#instance.#filters.forEach((filter) => {
            filter.setInitialState(initialState);
        });

        return HouseSearchFilters.#instance;
    }

    get all(): AbstractFilter[] {
        return Array.from(this.#filters.values());
    }

    get allParams(): Record<string, string | null> {
        const params: Record<string, string | null> = {};

        this.#filters.forEach((filter) => {
            Object.entries(filter.parameters).forEach(([key, value]) => {
                params[key] = value;
            });
        });

        return params;
    }

    get activeParams(): Record<string, string> {
        const params: Record<string, string> = {};

        this.#filters.forEach((filter) => {
            Object.entries(filter.parameters).forEach(([key, value]) => {
                if (value !== null) {
                    params[key] = value;
                }
            });
        });

        return params;
    }

    constructor(filters: Map<string, AbstractFilter>) {
        this.#filters = new Map(filters);
        this.#init();
    }

    public clear(): void {
        this.#filters.forEach((filter, key) => {
            if (HouseSearchFilters.#persistentFilters.includes(key)) {
                return;
            }

            filter.clear();
        });
    }

    public forEach(callback: (filter: AbstractFilter, key?: string) => void): void {
        this.#filters.forEach(callback);
    }

    public get(name: string): AbstractFilter | undefined {
        return this.#filters.get(name);
    }

    public update(subject: Subject): void {
        if (!(subject instanceof PaginationFilter)) {
            const filter: PaginationFilter = this.#filters.get('pagination') as PaginationFilter;
            filter.resetSkip();
        }
    }

    #init(): void {
        this.#filters.forEach((filter) => filter.attach(this));
    }
}

const params = new Map();

const aggregates = HouseSearchFilters.aggregateFilters;
const mapper = (value: string | string[], key: string): void => {
    if (params.has(key) && !aggregates.includes(key)) {
        return;
    }

    if (!aggregates.includes(key)) {
        params.set(key, value.toString());
        return;
    }

    const valueString = params.get(key);
    const values = valueString ? valueString.split(',') : [];

    if (!values.includes(value)) {
        values.push(value);
    }

    params.set(key, values.join(','));
};

// @ts-ignore
const queryParams = window.queryParams || {};
const values = <Map<string, string>>new Map(Object.entries(queryParams));
values.forEach(mapper);

HouseSearchFilters.getInstance(params);

export default HouseSearchFilters;
