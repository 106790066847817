import AbstractFilter from '../../../common/search/store/AbstractFilter';

export default class GeoFilter extends AbstractFilter {
    static NAME = 'geobox';

    #geobox: string | null = null;

    set geobox(value: string) {
        if (this.#geobox === value) return;

        this.#geobox = value;
        this.notify();
    }

    get parameters(): Record<string, string | null> {
        return {
            geobox: this.#geobox
        };
    }

    public setInitialState(): void {
        this.#geobox = null;
    }

    public clear(): void {
        this.#geobox = null;
        this.notify();
    }
}
