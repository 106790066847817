import AbstractSubject from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';

export type InitialFilterState = Map<string, string>;

abstract class AbstractFilter extends AbstractSubject {
    public abstract get parameters(): Record<string, string | null>;

    public abstract setInitialState(state: InitialFilterState): void;

    public abstract clear(): void;
}

export default AbstractFilter;
