const enum SearchTrackingEvents {
    DESTINATION = 'searchbar_searchterm',
    TRAVEL_PARTY = 'searchbar_travel_party',
    FLEXIBLE_ARRIVAL_DATE = 'searchbar_calendar_flexible_arrival',
    CALENDAR_TYPE = 'searchbar_calendar_type',
    DATE_SELECTION = 'searchbar_calendar_dates',
    HOUSE_ID = 'searchbar_house_id',
    FLEXIBLE_SEARCH = 'searchbar_flexible_dates',
    FILTER_SELECT = 'filter_select',
    PRICE_RANGE = 'filter_price',
    BEDROOM = 'filter_bedrooms',
    FILTER_RADIUS = 'filter_radius',
    DELETE_ALL_FILTERS = 'filter_remove_all',
    FILTER_UNSELECT = 'filter_unselect'
}

export enum DeleteAllFilterLocation {
    SEARCH_RESULTS_SELECTED_FILTERS = 'search results selected filters',
    SEARCH_RESULTS_NOTIFICATION = 'search results notification'
}

export enum FilterLocation {
    SEARCH_RESULTS_SELECTED_FILTERS = 'search results selected filters',
    SEARCH_RESULTS_AGGREGATION_FILTERS = 'search results aggregation filters'
}

export default SearchTrackingEvents;
