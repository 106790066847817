import AbstractFilter, { InitialFilterState } from '../../../common/search/store/AbstractFilter';

export default class TravelPartyFilter extends AbstractFilter {
    static NAME = 'travelParty';

    #adults = 0;

    #children = 0;

    #babies = 0;

    #pets = 0;

    clear(): void {
        this.#adults = 0;
        this.#children = 0;
        this.#babies = 0;
        this.#pets = 0;

        this.notify();
    }

    set adults(count: number) {
        if (this.#adults === count) return;

        this.#adults = count;
        this.notify();
    }

    set children(count: number) {
        if (this.#children === count) return;

        this.#children = count;
        this.notify();
    }

    set babies(count: number) {
        if (this.#babies === count) return;

        this.#babies = count;
        this.notify();
    }

    set pets(count: number) {
        if (this.#pets === count) return;

        this.#pets = count;
        this.notify();
    }

    get parameters(): Record<string, string | null> {
        return {
            adults: this.#adults ? this.#adults.toString() : null,
            children: this.#children ? this.#children.toString() : null,
            babies: this.#babies ? this.#babies.toString() : null,
            pets: this.#pets ? this.#pets.toString() : null
        };
    }

    setInitialState(state: InitialFilterState): void {
        const adults = state.get('adults');
        const children = state.get('children');
        const babies = state.get('babies');
        const pets = state.get('pets');

        this.#adults = adults ? Number(adults) : 0;
        this.#children = children ? Number(children) : 0;
        this.#babies = adults ? Number(babies) : 0;
        this.#pets = adults ? Number(pets) : 0;
    }
}
