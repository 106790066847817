import AbstractFilter, { InitialFilterState } from '../../../common/search/store/AbstractFilter';

export default class PriceFilter extends AbstractFilter {
    static NAME = 'price';

    #min = 0;

    #enabled = true;

    set min(value: number) {
        if (this.#min === value) return;

        this.#min = value;
        this.notify();
    }

    #max: number | null = null;

    set max(value: number | null) {
        if (this.#max === value) return;

        this.#max = value;
        this.notify();
    }

    set enabled(enabled: boolean) {
        const old = this.#enabled;
        this.#enabled = enabled;

        if (old !== enabled) this.notify();
    }

    clear(): void {
        this.#min = 0;
        this.#max = null;
        this.notify();
    }

    get parameters(): Record<string, string | null> {
        if (!this.#enabled) return { minPrice: null, maxPrice: null };

        return {
            minPrice: this.#min > 0 ? this.#min.toString() : null,
            maxPrice: this.#max && this.#max < 10000 ? this.#max.toString() : null
        };
    }

    setInitialState(state: InitialFilterState): void {
        const min = state.get('minPrice');
        const max = state.get('maxPrice');
        this.#min = Number(min) || 0;
        this.#max = Number(max) || null;
    }
}
