import AbstractFilter, { InitialFilterState } from '../../../common/search/store/AbstractFilter';

export default class PopularPageFilter extends AbstractFilter {
    static NAME = 'popularPage';

    #popular: string | null = null;

    #naturalArea: string | null = null;

    get parameters(): Record<string, string | null> {
        return {
            popular: this.#popular,
            naturalArea: this.#naturalArea
        };
    }

    public setInitialState(state: InitialFilterState): void {
        const popular = state.get('popular');
        const naturalArea = state.get('naturalArea');
        this.#popular = popular || null;
        this.#naturalArea = naturalArea || null;
    }

    public clear(): void {
        this.#popular = null;
        this.#naturalArea = null;
        this.notify();
    }
}
