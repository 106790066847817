import '@naturehouse/design-system/components/atoms/travel-company-input/TravelCompanyInput';
import '@naturehouse/design-system/components/molecules/form-field/FormField';
import '../../../modules/search/components/FlexibleDurationPicker';
import '../../../modules/search/components/FlexibleOptions';
import CalendarModalConnector from '../../../modules/search/storeConnectors/CalendarModalConnector';
import StayDurationTypeToggleConnector from '../../../modules/search/storeConnectors/StayDurationTypeToggleConnector';
import '../dateInput/StayDurationInputElement';
import './TravelPartySearch';

class SearchBar extends HTMLElement {
    constructor() {
        super();

        StayDurationTypeToggleConnector.connectToElement(
            'nh-modal[data-for="nh-stay-duration"] [data-role="stay-type-toggle"]'
        );
        CalendarModalConnector.connectToElement('nh-modal[data-for="nh-stay-duration"]');
    }
}

if (!customElements.get('nh-search-bar')) {
    customElements.define('nh-search-bar', SearchBar);
}
