import AbstractFilter, { InitialFilterState } from '../../../common/search/store/AbstractFilter';

export default class FlexibleArrivalDateFilter extends AbstractFilter {
    static NAME = 'flexibleArrivalDate';

    #flexibleRange = 0;

    #enabled = true;

    set enabled(enabled: boolean) {
        const old = this.#enabled;

        this.#enabled = enabled;
        if (old !== enabled) this.notify();
    }

    get enabled(): boolean {
        return this.#enabled;
    }

    set flexibleRange(value: number) {
        if (this.#flexibleRange === value) return;

        this.#flexibleRange = value;
        this.notify();
    }

    get parameters(): Record<string, string | null> {
        if (!this.#flexibleRange || !this.#enabled) {
            return { flexibleArrivalDate: null };
        }

        return { flexibleArrivalDate: this.#flexibleRange.toString() };
    }

    public setInitialState(state: InitialFilterState): void {
        const flexibleDurationType = state.get('flexibleDurationType');
        const value = state.get('flexibleArrivalDate');
        this.#flexibleRange = value ? Number(value) : 0;
        this.#enabled = flexibleDurationType === undefined;
    }

    public clear(): void {
        this.flexibleRange = 0;
    }
}
