import AbstractFilter, { InitialFilterState } from './AbstractFilter';

export default class PaginationFilter extends AbstractFilter {
    static #instance: PaginationFilter | null;

    static NAME = 'pagination';

    #skip = 0;

    #limit = 10;

    public static getInstance(): PaginationFilter {
        if (!this.#instance) {
            this.#instance = new PaginationFilter();
        }

        return this.#instance;
    }

    get parameters(): Record<string, string> {
        return {
            skip: this.#skip.toString(),
            limit: this.#limit.toString()
        };
    }

    public setPagination(skip: number, limit: number): void {
        this.#skip = skip;
        this.#limit = limit;
        this.notify();
    }

    public resetSkip(): void {
        this.#skip = 0;
    }

    public clear(): void {
        this.resetSkip();
    }

    public setInitialState(state: InitialFilterState): void {
        const skip = state.get('skip');
        const limit = state.get('limit');
        this.#skip = skip !== undefined ? parseInt(skip, 10) : 0;
        this.#limit = limit !== undefined ? parseInt(limit, 10) : 10;
    }
}
