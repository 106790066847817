import AbstractFilter, { InitialFilterState } from '../../../common/search/store/AbstractFilter';

export default class KeywordFilter extends AbstractFilter {
    static NAME = 'keyword';

    #keyword: string | null = null;

    get parameters(): Record<string, string | null> {
        return { keyword: this.#keyword };
    }

    set keyword(keyword: string) {
        this.#keyword = keyword;
        const keywordField: HTMLInputElement | null =
            document.querySelector('input[name="keyword"]');
        if (keywordField) {
            keywordField.value = keyword;
        }

        this.notify();
    }

    public clear(): void {
        this.#keyword = null;
        this.notify();
    }

    public setInitialState(state: InitialFilterState): void {
        const keyword = state.get('keyword');
        this.#keyword = keyword || null;
    }
}
