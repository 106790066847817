import TextToggle from '@naturehouse/design-system/components/atoms/text-toggle/TextToggle';
import { Observer, Subject } from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';
import BigQuery from '../../../common/BigQuery';
import { TrackingTimeout } from '../../../common/GoogleAnalyticsEventsBase';
import SearchTrackingEvents from '../../../enums/searchTrackingEvents';
import DatePickerCalendar, {
    DatePickerCalendarEvents
} from '../../../modules/calendar/webComponents/DatePickerCalendar';
import FlexibleDurationPicker from '../../../modules/search/components/FlexibleDurationPicker';
import FlexibleOptions from '../../../modules/search/components/FlexibleOptions';
import StayDurationTypeState, {
    StayDurationType
} from '../../../modules/search/store/StayDurationTypeState';
import TravelPeriodUIManager from '../../../common/search/travel-period/TravelPeriodUIManager';
import TravelPeriodStateManager from '../../../common/search/travel-period/TravelPeriodStateManager';
import HouseSearchFilters from '../../../modules/search/store/HouseSearchFilters';
import ArrivalDepartureFilter from '../../../modules/search/store/ArrivalDepartureFilter';
import Modal from '../modal/Modal';

class StayDurationInputElement extends HTMLElement implements Observer {
    readonly #modal = new Modal({
        title: this.getAttribute('title') || 'Title',
        classList: ['search-form-modal', 'nh-stay-duration'],
        hidden: true
    });

    readonly #state: StayDurationTypeState = StayDurationTypeState.getInstance();

    readonly #travelPeriodStateManager = TravelPeriodStateManager.getInstance();

    readonly #travelPeriodUIManager = TravelPeriodUIManager;

    readonly #arrivalDateInput: HTMLInputElement = this.querySelector(
        '[data-role="arrival-date-input"]'
    ) as HTMLInputElement;

    readonly #departureDateInput: HTMLInputElement = this.querySelector(
        '[data-role="departure-date-input"]'
    ) as HTMLInputElement;

    readonly #stayDurationFieldContainer: HTMLElement = this.querySelector(
        '[data-role="stay-duration-field"]'
    ) as HTMLElement;

    readonly #flexbileDurationFieldContainer: HTMLElement = this.querySelector(
        '[data-role="flexible-duration-field"]'
    ) as HTMLElement;

    readonly #arrivalDepartureFilter: ArrivalDepartureFilter | undefined;

    #flexibleDurationTypeInput: HTMLInputElement | null = null;

    #flexibleDurationMonthsInput: HTMLInputElement | null = null;

    #flexibleDurationPicker: FlexibleDurationPicker | null = null;

    #flexibleOptionsInput: FlexibleOptions | null = null;

    #flexibleClearButton: HTMLButtonElement | null = null;

    #calendar: DatePickerCalendar | null = null;

    #flexibleArrivalInput: HTMLInputElement | null = null;

    public constructor() {
        super();
        this.#modal.dataset.for = 'nh-stay-duration';
        this.#modal.dataset.pw = 'datepicker-calendar-modal';
        this.#travelPeriodStateManager.attach(this);

        const filters = HouseSearchFilters.getInstance();
        this.#arrivalDepartureFilter = filters.get(ArrivalDepartureFilter.NAME) as
            | ArrivalDepartureFilter
            | undefined;
    }

    public update(subject: Subject): void {
        if (subject instanceof StayDurationTypeState) {
            this.#switchInputs(subject.type === StayDurationType.CALENDAR);
            this.#determineClearButtonStateFlexibleInput();

            this.#trackCalendarTypeChange();
        }

        if (subject instanceof TravelPeriodStateManager && this.#arrivalDepartureFilter) {
            const { arrivalDate, departureDate } = subject;

            this.#arrivalDepartureFilter.setDateRange({
                arrivalDate: arrivalDate?.toISOString().split('T')[0] ?? null,
                departureDate: departureDate?.toISOString().split('T')[0] ?? null
            });
        }
    }

    protected connectedCallback(): void {
        const template = this.querySelector('#datepicker-calendar-content') as HTMLTemplateElement;
        const headerTemplate = this.querySelector(
            '#calendar-input-modal-header'
        ) as HTMLTemplateElement;
        const footerTemplate = this.querySelector(
            '#calendar-input-modal-footer'
        ) as HTMLTemplateElement;
        const flexibleOptionsInput = this.querySelector('nh-flexible-options') as FlexibleOptions;
        const flexibleArrivalInput = this.querySelector(
            '[data-role="flexibleArrivalDate"]'
        ) as HTMLInputElement;

        const flexibleClearButton = this.querySelector(
            '[data-role="flexible-clear"]'
        ) as HTMLButtonElement;

        if (
            !template ||
            !headerTemplate ||
            !footerTemplate ||
            !flexibleArrivalInput ||
            !flexibleOptionsInput ||
            !flexibleClearButton
        ) {
            throw new Error('Templates or inputs not found');
        }

        this.#flexibleOptionsInput = flexibleOptionsInput;
        this.#flexibleClearButton = flexibleClearButton;
        this.#flexibleArrivalInput = flexibleArrivalInput;

        this.#flexibleDurationTypeInput = this.querySelector('[name="flexibleDurationType"]');
        this.#flexibleDurationMonthsInput = this.querySelector('[name="flexibleBookingMonths"]');

        const header = document.importNode(headerTemplate.content, true);
        const content = document.importNode(template.content, true);
        const footer = document.importNode(footerTemplate.content, true);
        this.#calendar = content.querySelector('datepicker-calendar') as DatePickerCalendar;

        this.#travelPeriodUIManager.dialog = this.#modal;
        this.#travelPeriodUIManager.arrivalDateInput = this.#arrivalDateInput;
        this.#travelPeriodUIManager.departureDateInput = this.#departureDateInput;
        this.#travelPeriodUIManager.calendar = this.#calendar;
        this.#travelPeriodUIManager.arrivalDateField = header.querySelector(
            'nh-date-field[data-name="arrivalDate"]'
        );
        this.#travelPeriodUIManager.departureDateField = header.querySelector(
            'nh-date-field[data-name="departureDate"]'
        );
        this.#travelPeriodUIManager.weekDays = header.querySelector(
            'ol[is="nh-calendar-week-days"]'
        );

        const arrivalDateValue = this.#travelPeriodUIManager.getArrivalDateValue();
        const departureDateValue = this.#travelPeriodUIManager.getDepartureDateValue();

        const arrivalDate = arrivalDateValue ? new Date(arrivalDateValue) : null;
        const departureDate = departureDateValue ? new Date(departureDateValue) : null;

        this.#travelPeriodStateManager.setInitialValues({ arrivalDate, departureDate });

        this.#modal.header = header;
        this.#modal.content = content;
        this.#modal.footer = footer;

        document.body.append(this.#modal);

        this.#flexibleDurationPicker = this.#modal.content.querySelector(
            'nh-flexible-duration-picker'
        ) as FlexibleDurationPicker | null;

        this.#determineClearButtonStateFlexibleInput();
        this.#addEventListeners();

        this.#state.attach(this);
    }

    #addEventListeners(): void {
        this.#flexibleDurationPicker?.addEventListener('change', this.#onFlexibleDurationChange);

        this.#flexibleOptionsInput?.addEventListener('click', this.#showModal);

        this.#flexibleOptionsInput?.addEventListener(
            'change',
            this.#determineClearButtonStateFlexibleInput.bind(this)
        );

        this.#calendar?.addEventListener(
            DatePickerCalendarEvents.DATE_RANGE_SELECTED,
            (event: Event) => {
                const customEvent = event as CustomEvent;
                this.#onCalendarSelect(customEvent);
            }
        );

        const flexibleArrivalSelectorInput = this.#modal.footer.querySelector(
            'input[name="flexibleArrivalDate"]'
        ) as HTMLInputElement | null;

        if (flexibleArrivalSelectorInput) {
            flexibleArrivalSelectorInput.addEventListener('change', () => {
                if (!this.#flexibleArrivalInput) return;
                this.#flexibleArrivalInput.value = flexibleArrivalSelectorInput.value;
                this.#trackFlexibleArrivalDateChange();
            });
        }

        this.#setDoneButtonEvents();
        this.#setClearButtonEvents();

        this.#flexibleClearButton?.addEventListener('click', this.#clearFlexibleInput.bind(this));
    }

    #removeEventListeners(): void {
        this.#flexibleDurationPicker?.removeEventListener('change', this.#onFlexibleDurationChange);

        this.#flexibleOptionsInput?.removeEventListener('click', this.#showModal);

        this.#flexibleOptionsInput?.removeEventListener(
            'change',
            this.#determineClearButtonStateFlexibleInput.bind(this)
        );

        this.#flexibleClearButton?.removeEventListener(
            'click',
            this.#clearFlexibleInput.bind(this)
        );

        this.#flexibleArrivalInput?.removeEventListener(
            'change',
            this.#trackFlexibleArrivalDateChange.bind(this)
        );
    }

    protected disconnectedCallback(): void {
        this.#modal.remove();

        this.#flexibleDurationPicker?.removeEventListener('change', this.#onFlexibleDurationChange);

        this.#removeEventListeners();
    }

    readonly #showModal = (): void => {
        if (this.#modal.isOpen || !this.#flexibleOptionsInput) {
            return;
        }

        this.#travelPeriodUIManager.openDialog(this.#flexibleOptionsInput);
    };

    readonly #onFlexibleDurationChange = (): void => {
        if (
            !this.#flexibleDurationPicker ||
            !this.#flexibleDurationTypeInput ||
            !this.#flexibleDurationMonthsInput
        ) {
            return;
        }

        this.#flexibleDurationTypeInput.value = this.#flexibleDurationPicker.type;
        this.#flexibleDurationMonthsInput.value = this.#flexibleDurationPicker.months.join(',');
        this.#flexibleDurationTypeInput.dispatchEvent(new Event('change'));
    };

    #onCalendarSelect(event: CustomEvent): void {
        const detail = event.detail;

        this.#travelPeriodStateManager.update({
            arrivalDate: detail.start,
            departureDate: detail.end
        });

        this.#trackDateSelection();
    }

    #switchInputs(isCalendarInput: boolean): void {
        if (!this.#arrivalDateInput || !this.#flexibleOptionsInput) return;

        const hiddenArrivalInput = this.querySelector('[data-name="arrivalDate"]');
        const hiddenDepartureInput = this.querySelector('[data-name="departureDate"]');

        hiddenArrivalInput?.toggleAttribute('disabled', !isCalendarInput);
        hiddenDepartureInput?.toggleAttribute('disabled', !isCalendarInput);

        this.#stayDurationFieldContainer.toggleAttribute('hidden', !isCalendarInput);
        this.#arrivalDateInput.toggleAttribute('disabled', !isCalendarInput);
        this.#departureDateInput.toggleAttribute('disabled', !isCalendarInput);

        this.#flexbileDurationFieldContainer.toggleAttribute('hidden', isCalendarInput);
        this.#flexibleOptionsInput.toggleAttribute('disabled', isCalendarInput);
    }

    #determineClearButtonStateFlexibleInput(): void {
        if (this.#flexibleOptionsInput === null || this.#flexibleClearButton === null) {
            return;
        }

        this.#flexibleClearButton.toggleAttribute(
            'hidden',
            this.#state.type === StayDurationType.CALENDAR
        );
    }

    #clearFlexibleInput(): void {
        const stayTypeToggle = document.querySelector(
            '[data-role="stay-type-toggle"]'
        ) as TextToggle | null;
        if (stayTypeToggle) {
            stayTypeToggle.value = StayDurationType.CALENDAR;
        }

        this.#state.type = StayDurationType.CALENDAR;
        this.#flexibleDurationPicker?.dispatchEvent(new Event('clear'));
    }

    #trackFlexibleArrivalDateChange(): void {
        const data: GoogleAnalytics4FlexibleArrivalDateEvent = {
            value: Number(this.#flexibleArrivalInput?.value),
            page: window.location.pathname === '/' ? 'homepage' : 'searchpage'
        };

        BigQuery.track({
            eventName: SearchTrackingEvents.FLEXIBLE_ARRIVAL_DATE,
            eventParams: [
                { eventKey: 'value', eventValue: data.value.toString() },
                { eventKey: 'page', eventValue: data.page }
            ]
        });
    }

    #trackCalendarTypeChange(): void {
        const data: GoogleAnalytics4CalendarTypeChangeEvent = {
            type: this.#state.type,
            page: window.location.pathname === '/' ? 'homepage' : 'searchpage'
        };

        BigQuery.track({
            eventName: SearchTrackingEvents.CALENDAR_TYPE,
            eventParams: [
                { eventKey: 'type', eventValue: data.type },
                { eventKey: 'page', eventValue: data.page }
            ]
        });
    }

    readonly #trackDateSelection = (): void => {
        const arrivalDate = this.#travelPeriodUIManager.getArrivalDateValue();
        const departureDate = this.#travelPeriodUIManager.getDepartureDateValue();

        const data: GoogleAnalytics4DateSelectionEvent = {
            arrival: arrivalDate ?? '',
            departure: departureDate ?? '',
            page: window.location.pathname === '/' ? 'homepage' : 'searchpage'
        };

        BigQuery.trackWithDelay(
            {
                eventName: SearchTrackingEvents.DATE_SELECTION,
                eventParams: [
                    { eventKey: 'arrival', eventValue: data.arrival },
                    { eventKey: 'departure', eventValue: data.departure },
                    { eventKey: 'page', eventValue: data.page }
                ]
            },
            TrackingTimeout.DATE_SEARCH_DELAY
        );
    };

    #setDoneButtonEvents(): void {
        const doneButton = this.#modal.footer.querySelector(
            '[data-role="close-modal"]'
        ) as HTMLButtonElement | null;

        if (doneButton) {
            doneButton.addEventListener('click', () => {
                if (this.#modal.isOpen) {
                    this.#modal.close();
                }
            });
        }
    }

    #setClearButtonEvents(): void {
        const clearButton = this.#modal.footer.querySelector(
            '[data-role="clear"]'
        ) as HTMLButtonElement | null;

        if (clearButton) {
            clearButton.addEventListener('click', () => {
                this.#clearFlexibleInput();
                this.#travelPeriodStateManager.clearArrivalDate();
            });
        }
    }
}

if (!customElements.get('nh-stay-duration')) {
    customElements.define('nh-stay-duration', StayDurationInputElement);
}
