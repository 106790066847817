import AbstractFilter, { InitialFilterState } from '../../../common/search/store/AbstractFilter';

export default class ArrivalDepartureFilter extends AbstractFilter {
    static NAME = 'arrivalDeparture';

    #enabled = true;

    #dateRange: ArrivalDepartureDates = { arrivalDate: null, departureDate: null };

    set enabled(enabled: boolean) {
        const old = this.#enabled;

        this.#enabled = enabled;
        if (old !== enabled) this.notify();
    }

    get enabled(): boolean {
        return this.#enabled;
    }

    setDateRange(dateRange: ArrivalDepartureDates): void {
        if (
            this.#dateRange.arrivalDate === dateRange.arrivalDate &&
            this.#dateRange.departureDate === dateRange.departureDate
        ) {
            return;
        }

        this.#dateRange = dateRange;
        this.notify();
    }

    get dateRange(): ArrivalDepartureDates {
        return { ...this.#dateRange };
    }

    set arrivalDate(value: string) {
        if (this.#dateRange.arrivalDate === value) return;

        this.#dateRange.arrivalDate = value;
        this.notify();
    }

    set departureDate(value: string) {
        if (this.#dateRange.departureDate === value) return;

        this.#dateRange.departureDate = value;
        this.notify();
    }

    clear(): void {
        if (this.#dateRange.arrivalDate === null && this.#dateRange.departureDate === null) {
            return;
        }

        this.#dateRange = { arrivalDate: null, departureDate: null };
        this.notify();
    }

    get parameters(): Record<string, string | null> {
        if (!this.#enabled) return { arrivalDate: null, departureDate: null };

        const { arrivalDate, departureDate } = this.#dateRange;
        return { arrivalDate, departureDate };
    }

    setInitialState(state: InitialFilterState): void {
        const arrivalDate = state.get('arrivalDate');
        const departureDate = state.get('departureDate');
        this.#dateRange = {
            arrivalDate: arrivalDate || null,
            departureDate: departureDate || null
        };
    }
}
